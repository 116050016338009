import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import Button from "components/CustomButtons/Button.jsx"

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx"

class WorkSection extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  state = { email: "", name: "", message: "", submitted: false }

  sendToSlack(event) {
    const slackMessage = `Message Received from: \`${
      this.state.name
    }\`, Message: \`${this.state.message}\`, Email: \`${this.state.email}\``
    const body = JSON.stringify({
      text: slackMessage,
    })
    const url = `${process.env.GATSBY_SLACK_HOOK}`

    event.preventDefault()

    fetch(url, {
      method: "POST",
      headers: {
        // using 'application/json' here fails to handle CORS preflight 'options' request as it should on frontend JavaScript code used 'application/x-www-form-urlencoded' https://stackoverflow.com/questions/45752537/slack-incoming-webhook-request-header-field-content-type-is-not-allowed-by-acce
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body,
    })

    this.setState(prevState => {
      return { ...prevState, submitted: true }
    })
  }

  handleChange(event) {
    const { id, value } = event.target

    this.setState(prevState => {
      return { ...prevState, [id]: value }
    })
  }

  render() {
    const { classes } = this.props
    const description = this.state.submitted
      ? "Thanks for reaching out! We will contact you shortly."
      : "Are you interested in partnering with Utah Polynesian Professionals? Do you have a question? Reach out to us!"
    return (
      <div className={classes.section} id="contact">
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Work with us</h2>
            <h4 className={classes.description}>{description} </h4>
            <form
              onSubmit={e => {
                this.sendToSlack(e)
              }}
            >
              {!this.state.submitted && (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Your Name"
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: this.handleChange,
                        value: this.state.name,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Your Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: this.handleChange,
                        value: this.state.email,
                      }}
                    />
                  </GridItem>
                  <CustomInput
                    labelText="Your Message"
                    id="message"
                    value={this.state.message}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.textArea,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      onChange: this.handleChange,
                      values: this.state.message,
                    }}
                  />
                  <GridContainer justify="center">
                    <GridItem
                      xs={12}
                      sm={12}
                      md={4}
                      className={classes.textCenter}
                    >
                      <Button color="primary" type="submit">
                        Send Message
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              )}
            </form>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(workStyle)(WorkSection)
